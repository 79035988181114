import React from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";

import ToolsItem from "./ToolsItem";
import { ReactComponent as SprinklerRoundSVG } from "./../../assets/sprinkler-round.svg";
import { ReactComponent as SprinklerRectSVG } from "./../../assets/sprinkler-strip.svg";
import { ReactComponent as RzwsSVG } from "./../../assets/tree-icon-dis.svg";
import { ReactComponent as RaisedBedSVG } from "./../../assets/hochbeet-icon-dis.svg";
import { ReactComponent as SprinklerRecalcSVG } from "./../../assets/sprinkler-recalc.svg";
import useStores from "../../hooks/useStores";

function SprinklerTools({
  selectedTool,
  setSelectedTool,
  labels,
  calculateSprinklers,
}) {
  const { formatMessage } = useIntl();

  const tools = [
    {
      name: "sprinkler-circle",
      icon: <SprinklerRoundSVG />,
      label: "sprinklerCircle",
    },
    {
      name: "sprinkler-rect",
      icon: <SprinklerRectSVG />,
      label: "sprinklerRect",
    },
    {
      name: "rzws",
      icon: <RzwsSVG />,
      label: "rzws",
    },
    {
      name: "raised-bed",
      icon: <RaisedBedSVG />,
      label: "raisedBed",
    },
  ];

  return (
    <React.Fragment>
      {tools.map(({ name, icon, label, hasLine }) => {
        return (
          <ToolsItem
            id={`${name}-tool`}
            key={`tool-${name}`}
            icon={icon}
            type={name}
            isActive={selectedTool === name}
            onClick={() => {
              setSelectedTool(name);
            }}
            tooltip={
              labels?.[label]
                ? formatMessage({ id: labels[label]?.tooltip })
                : null
            }
          />
        );
      })}
      <ToolsItem
        icon={<SprinklerRecalcSVG />}
        onClick={async () => {
          await calculateSprinklers();
        }}
        hasLine={true}
        tooltip={formatMessage({ id: "texts.tools.sprinklersRecalc.tooltip" })}
      />
    </React.Fragment>
  );
}

let SprinklerToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <SprinklerTools
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      calculateSprinklers={uiState.calculateSprinklers}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
    />
  );
};

SprinklerToolsWithState = observer(SprinklerToolsWithState);
export default SprinklerToolsWithState;
