import { extendObservable, observable, action } from "mobx";

import {
  pointInArea,
  IrrigationQuantitySorter,
  hasIntersection,
  closestPointOnArea,
  NON_CROSSED_AREA_THRESHOLD,
} from "@dvsproj/ipat-core/areaUtils";
import { convertPlanJSON, offsetPlan } from "@dvsproj/ipat-core/planUtils";

import { sprinklersDistanceArray } from "@dvsproj/ipat-core/sprinklerUtils";

import sprinklerFactory from "../types/sprinklerFactory";
import rzwsFactory from "../types/rzwsFactory";
import raisedBedFactory from "../types/raisedBedFactory";
import sensorFactory from "../types/sensorFactory";
import systemElementFactory from "../types/systemElementFactory";
import areaFactory from "../types/areaFactory";
import pipeFactory from "../types/pipeFactory";
import pipePointFactory from "../types/pipePointFactory";
import cableFactory from "../types/cableFactory";
import injectPlanReactions from "./injectPlanReactions";
import injectPlanSerialization from "./injectPlanSerialization";
import injectBomItems from "./injectBomItems";
import injectPlanPipelines from "./injectPlanPipelines";
import { ceilWithThreshold } from "@dvsproj/ipat-core/formatter";
import { MP_ROTATOR_ID } from "@dvsproj/ipat-core/settings/settingsUtils";

/**
 * Creates a irrigation plan object
 * @param {*} param0 - plan json
 * @param {*} settingsState - state that contain a labels for UI
 * @returns plan object
 * Returned object contains
 * - sprinklerSetType = "mp" or "gd"
 * - scale = (user line length in px / value in meters entered by user)
 * - opacity
 * - offsets
 * - background - {width, height, src: (base64)}
 * - elements - all elements of thr plan: areas, sprinklers, system elements, pipes
 * - editable - false when plan is finalized or status is "to-check"
 * - unit - measurement unit of the plan elements sizes, values = ["pixel", "10cm"]
 * - status - irrigation plan status, helper parameter, default value - "created"
 *  possible values:
 *    [
 *    "created", "areas added", "sprinklers calculated", sprinklers is modified",
 *    "pipelines calculated", "pipelines is modified", "bom calculated", "bom is modified", "finalize", "checked", "to-check"
 *    ]
 * - sprinklersModified - helper parameter, not used, default value is false
 * - pipesModified - helper parameter, not used, default value is false
 * - bomModified - - helper parameter, not used, default value is false
 * - showWaterSupplyVideo - start to show a video when costumer places water source
 * - planningTime - the time in seconds people spend actually planning
 */
const planFactory = (
  {
    sprinklerSetType = MP_ROTATOR_ID,
    scale,
    opacity,
    background,
    elements,
    unit = "pixel",
    offsetX = 0,
    offsetY = 0,
    bomItems,
    status,
    sprinklersModified = false,
    pipesModified = false,
    sprinklersNeedToRecalculate = true,
    pipesNeedToRecalculate = true,
    sensorModified = false,
    bomModified = false,
    showSensorPlan = undefined,
    showWaterSupplyVideo = undefined,
    sprinklersAlgoSettings,
    planningTime = 0,
    isNotify,
    createdAt,
    maxStep = 0,
    version,
    pipelinesAlgo,
    bomType,
    irrigationTubeType = "bluLock_24mm", // IRRIGATION_TUBE_TYPE.BluLock24mm,
    pressureTubeType = "pe_25mm", // PRESSURE_TUBE_TYPE.PE_25mm,
  },
  settingsState,
  zoomState
) => {
  const plan = observable({
    sprinklerSetType,
    scale,
    opacity,
    background,
    scaleLength: null,
    sprinklersModified,
    pipesModified,
    sprinklersNeedToRecalculate,
    pipesNeedToRecalculate,
    sensorModified,
    bomModified,
    showSensorPlan,
    showWaterSupplyVideo,
    sprinklersAlgoSettings,
    planningTime,
    isNotify,
    createdAt:
      typeof createdAt === "string" ? Date.parse(createdAt) : createdAt,
    setCreatedAt(v) {
      plan.createdAt = typeof v === "string" ? Date.parse(v) : v;
    },
    maxStep,
    version,
    pipelinesAlgo,
    bomType,
    irrigationTubeType,
    pressureTubeType,
  });

  /**
   * Wrapper of the pipe point object
   * Contains additional getters:
   * - lines - returns all pipes that start or end at this pipe point
   * - areas - return all areas that is contain this pipe point
   * - selectedPoint - return selected point
   * - pipelines - returns all pipelines that is contain this pipe point
   */
  const pipelinePointFactory = action((data, settings) => {
    const point = pipePointFactory(data, settings);
    extendObservable(point, {
      get lines() {
        if (!plan.pipes || plan.pipes.length === 0) {
          return undefined;
        }
        return plan.pipes.filter(
          (pipe) => pipe.start === point.id || pipe.end === point.id
        );
      },
      get areas() {
        let arr = plan.areas ? plan.areas.slice() : [];
        return arr.filter((a) => pointInArea(point, a));
      },
      get selectedPoint() {
        return plan.pipePoints.find(({ id }) => id === plan.selectedPointId);
      },
      get selected() {
        return point.id === plan.selectedPointId;
      },
      get pipelines() {
        return plan.pipelines
          ? plan.pipelines.filter((p) => p.pointIds.indexOf(point.id) >= 0)
          : null;
      },
      get sprinkler() {
        let arr = null;
        if (point.isSystemElementPoint) {
          arr = plan.systemElements.filter(
            (elem) =>
              [
                "water-supply",
                "water-tap-point",
                "water-meter",
                "water-filter",
                "fertilizer",
                "valve-box",
                "combi-box",
              ].indexOf(elem.systemType) >= 0
          );
        } else if (point.isRzwsPoint) {
          arr = plan.rzws;
        } else if (point.isRaisedBedPoint) {
          arr = plan.raisedBeds;
        } else {
          arr = plan.sprinklers;
        }

        const parent = arr.find(
          ({ id, x, y }) =>
            (point.parentId != null && point.parentId === id) ||
            (point.parentId == null && x === point.x && y === point.y)
        );
        return parent;
      },
      get numberDriplinePointsInArea() {
        return point.driplineArea
          ? plan.pipePoints.filter((el) => {
              return el.isDriplinePoint && pointInArea(el, point.driplineArea);
            }).length
          : undefined;
      },
      get isNotConnectedToValve() {
        const pipeline = point.pipelines ? point.pipelines[0] : undefined;
        const lineTypes = ["sprinkler", "rzws-line", "raised-bed-line"];

        const connectableSystemElement =
          pipeline?.lineType === "system-element" &&
          (pipeline?.hasCombiBoxPoint ||
            pipeline?.hasWaterFilterPoint ||
            pipeline?.hasWaterMeterPoint ||
            pipeline?.hasCombiBoxPoint);

        const connectableLineType =
          lineTypes.includes(pipeline?.lineType) ||
          connectableSystemElement ||
          (pipeline?.lineType === "pressure-tubing" &&
            pipeline?.hasWaterTapPoint);

        return connectableLineType && pipeline?.numberOfConnectionToStart === 0;
      },
      get driplineValvesCount() {
        const waterSupply = plan.getSystemElementsByType("water-supply")?.[0];
        const { driplineKit } = settingsState.elements;

        // How much water in l/h does one square meter consume
        const areaConsumption = 15;

        // Number of valves in driplineArea
        return ceilWithThreshold(
          point.driplineArea.size * areaConsumption,
          waterSupply.waterQuantity * 1000,
          driplineKit.areaThreshold
        );
      },
    });
    return point;
  });

  /**
   * Generates a color for a pipe. Depends on injectPlanPipelines.
   */
  const generatePipeColor = () => {
    let defaultColors = [
      "#FE7500",
      "#FFE200",
      "#33BC12",
      "#A06DF4",
      "#49366A",
      "#00C4FF",
      "#BEE8E0",
    ];

    const pipeColors = plan.pipes
      ?.filter((p) => p.isStartLine)
      ?.map((p) => p.color);
    if (pipeColors && pipeColors.length > 0) {
      pipeColors.forEach((obj) => {
        defaultColors = defaultColors.filter(
          (c) => c.toUpperCase() !== obj.toUpperCase()
        );
      });
    }
    let color = defaultColors.length > 0 ? defaultColors[0] : undefined;
    while (!color) {
      color = Math.floor(Math.random() * 16777215).toString(16);
      for (let count = color.length; count < 6; count++) {
        color = "0" + color;
      }
      color = "#" + color;
      if (color === "#FF0000" || color === "#9EA1A2") color = undefined;
    }

    return color;
  };

  /**
   * Wrapper of the pipe object
   * Contains additional getters:
   * - startPoint, endPoint - pipe points by ids
   * - points - [startPoint, endPoint] array
   */
  const pipeElementFactory = action((data, settings) => {
    const color = data && data.color ? data.color : generatePipeColor();

    const elem = pipeFactory({ ...data, color }, settings);
    extendObservable(elem, {
      get startPoint() {
        if (!plan.pipePoints || plan.pipePoints.length === 0) {
          return undefined;
        }
        return plan.pipePoints.find((point) => point.id === elem.start);
      },
      get endPoint() {
        if (!plan.pipePoints || plan.pipePoints.length === 0) {
          return undefined;
        }
        return plan.pipePoints.find((point) => point.id === elem.end);
      },
      get points() {
        return [this.startPoint, this.endPoint];
      },
    });
    return elem;
  });

  /**
   * Wrapper of the sprinkler
   * Contains additional getters:
   * - inNonCrossedArea
   */
  const sprinklerElementFactory = action(
    ({ prsType, nozzleType, type, ...element }) => {
      let sprinkler = null;
      switch (type) {
        case "rzws":
          sprinkler = rzwsFactory(
            {
              type,
              ...element,
            },
            settingsState,
            plan
          );
          break;
        case "raised-bed":
          sprinkler = raisedBedFactory(
            {
              type,
              ...element,
            },
            settingsState,
            plan
          );
          break;
        default:
          sprinkler = sprinklerFactory(
            {
              nozzleType: nozzleType ?? prsType, // support for old plans
              type,
              ...element,
            },
            settingsState,
            plan
          );
          break;
      }

      extendObservable(sprinkler, {
        get inNonCrossedArea() {
          const threshold =
            NON_CROSSED_AREA_THRESHOLD != null
              ? NON_CROSSED_AREA_THRESHOLD
              : 10;
          let arr = plan.areas ? plan.areas.slice() : [];
          return (
            arr.filter((a) => {
              if (a.crossability === true) return false;

              let result = pointInArea(sprinkler, a);
              let cp = result ? closestPointOnArea(sprinkler, a) : null;

              return result && cp && cp.distance > threshold;
            }).length > 0
          );
        },
        hidden: false,
        setHidden: action((val) => {
          sprinkler.hidden = val;
        }),
      });
      return sprinkler;
    }
  );

  /**
   * Wrapper of the sensor
   * Contains additional getters:
   * - inNonCrossedArea
   */
  const sensorElementFactory = action((element) => {
    const sensor = sensorFactory(element, settingsState, plan);

    extendObservable(sensor, {
      get onArea() {
        const { sensorPrecipitationCoverage } = plan;
        if (sensorPrecipitationCoverage == null) return null;

        const {
          offsetX: rasterOffsetX,
          offsetY: rasterOffsetY,
          data: imgData,
          width: imgWidth,
          height: imgHeight,
        } = plan?.sensorPrecipitationCoverage;

        const converted = offsetPlan(
          convertPlanJSON(plan.toJSON, "10cm"),
          rasterOffsetX,
          rasterOffsetY
        );

        const convertedSensor = converted.elements.find(
          ({ id }) => sensor.id === id
        );

        //check if sensor is in dripline area
        let arr = plan.areas ? plan.areas.slice() : [];
        const driplineArea = arr.filter((a) => {
          if (a?.quantity !== "dripline") {
            return false;
          }

          let result = pointInArea(sensor, a);
          let cp = result ? closestPointOnArea(sensor, a) : null;

          return result && cp && cp.distance > 1;
        })?.[0];
        if (driplineArea != null) {
          const dripline = plan?.pipelines?.find(
            (p) =>
              p.lineType === "dripline" &&
              p?.driplinePoint?.driplineArea?.id === driplineArea.id
          );

          return { type: "dripline", area: driplineArea.id, id: dripline?.id };
        }

        if (convertedSensor == null) return null;

        const roundedX = Math.round(convertedSensor.x);
        const roundedY = Math.round(convertedSensor.y);
        const index = roundedY * (imgWidth * 4) + roundedX * 4;

        if (
          roundedX <= imgWidth &&
          roundedX >= 0 &&
          roundedY <= imgHeight &&
          roundedY >= 0 &&
          imgData[index] != null
        ) {
          if (
            imgData[index] === 0 &&
            imgData[index + 1] === 0 &&
            imgData[index + 2] === 0 &&
            imgData[index + 3] === 0
          ) {
            return null;
          }

          const hexR = imgData[index].toString(16).padStart(2, "0");
          const hexG = imgData[index + 1].toString(16).padStart(2, "0");
          const hexB = imgData[index + 2].toString(16).padStart(2, "0");

          const hex = `#${hexR}${hexG}${hexB}`.toUpperCase();
          const pipeline = plan?.pipelines?.find((p) => p.color === hex);

          return { type: "circuit", color: hex, id: pipeline?.id };
        }
        return null;
      },
      get isOnInvalidArea() {
        let area = sensor.onArea;
        return area == null;
      },
      hidden: false,
      setHidden: action((val) => {
        sensor.hidden = val;
      }),
    });
    return sensor;
  });

  /**
   * Wrapper of the water supply
   * Contains additional getters:
   * - hasShapeLimitError
   */
  const systemElementFactoryWrapper = action((element, settings, planObj) => {
    const driplineKit = settings ? settings.elements.driplineKit : null;
    const systemElement = systemElementFactory(element, settings, planObj);
    extendObservable(systemElement, {
      get hasShapeLimitError() {
        if (!systemElement || systemElement.systemType !== "water-supply") {
          return false;
        }

        const driplineAreas = planObj.areas
          ? planObj.areas.filter(({ quantity }) => quantity === "dripline")
          : [];

        return (
          systemElement &&
          (systemElement.waterQuantity <= 0 ||
            (driplineKit &&
              driplineKit.waterConsumption &&
              driplineAreas &&
              driplineAreas.length > 0 &&
              systemElement.waterQuantity * 1000 <
                driplineKit.waterConsumption))
        );
      },
    });
    return systemElement;
  });

  /**
   * Generate the plan elements by type
   * @param {*} element
   * @param {*} settings
   */
  const elementFactory = (element, allElements = []) => {
    let factory = null;
    const parent =
      element && element.type === "pipeline-point" && element.parentId == null
        ? allElements.find(
            ({ x, y, type, systemType }) =>
              ((type === "sprinkler" &&
                element.pointType === "sprinkler-point") ||
                (type === "system-element" &&
                  systemType === "valve-box" &&
                  element.pointType === "start-point") ||
                (type === "rzws" && element.pointType === "rzws-point") ||
                (type === "raised-bed" &&
                  element.pointType === "raised-bed-point")) &&
              x === element.x &&
              y === element.y
          )
        : undefined;
    if (parent) {
      element.parentId = parent.id;
    }

    switch (element.type) {
      case "pipe":
        factory = pipeElementFactory(element, settingsState);
        break;
      case "pipeline-point":
        factory = pipelinePointFactory(element, settingsState);
        break;
      case "sprinkler":
      case "rzws":
      case "raised-bed":
        if (element.x != null && element.y != null) {
          factory = sprinklerElementFactory(element);
        }
        break;
      case "irrigationValveCable":
        if (element.startId == null) {
          const valveBox = elements.find((se) => {
            return (
              se.systemType === "valve-box" &&
              element.points.some((p) => p.x === se.x && p.y === se.y)
            );
          });
          element.startId = valveBox?.id;
        }

        if (element.stopId == null) {
          const controller = elements.find((se) => {
            return (
              se.systemType === "controller" &&
              element.points.some((p) => p.x === se.x && p.y === se.y)
            );
          });
          element.stopId = controller?.id;
        }

        factory = cableFactory(element, settingsState);
        break;
      case "waterMeterCable":
        if (element.startId == null) {
          const waterSupply = elements.find((se) => {
            return (
              se.systemType === "water-supply" &&
              element.points.some((p) => p.x === se.x && p.y === se.y)
            );
          });
          element.startId = waterSupply?.id;
        }

        if (element.stopId == null) {
          const controller = elements.find((se) => {
            return (
              se.systemType === "controller" &&
              element.points.some((p) => p.x === se.x && p.y === se.y)
            );
          });
          element.stopId = controller?.id;
        }

        factory = cableFactory(element, settingsState);
        break;
      case "system-element":
        factory = systemElementFactoryWrapper(element, settingsState, plan);
        break;
      case "sensor":
        const curIdx = allElements
          .filter((e) => e.type === "sensor")
          .findIndex((e) => e.id === element.id);

        factory = sensorElementFactory({
          ...element,
          name: element.name ?? `S${curIdx + 1}`,
        });
        break;
      case "area":
        factory = areaFactory(element, settingsState, plan);
        extendObservable(factory, {
          get otherAreas() {
            if (!plan.elements || plan.elements.length === 0) {
              return undefined;
            }
            return plan.elements.filter(
              (e) => e.type === "area" && e.id !== element.id
            );
          },
          get isWatermarkCanBeShow() {
            if (factory && factory.quantity === "should") {
              const watermarkCoords =
                factory.isCircle || factory.isRectangle
                  ? factory
                  : factory.pointsCenter;
              if (watermarkCoords) {
                const watermarkArea = {
                  id: "watermark",
                  x: watermarkCoords.x,
                  y: watermarkCoords.y,
                  width:
                    200 *
                    (zoomState && zoomState.zoomDelta
                      ? zoomState.zoomDelta
                      : 1),
                  height:
                    40 *
                    (zoomState && zoomState.zoomDelta
                      ? zoomState.zoomDelta
                      : 1),
                  startAngle: 0,
                  areaType: "rectangle",
                  points: [],
                };

                return factory.isCircle || factory.isRectangle
                  ? !hasIntersection(factory.toJSON, [watermarkArea], false) &&
                      !pointInArea(
                        {
                          x: factory.x - factory.width / 2,
                          y: factory.y - factory.height / 2,
                        },
                        watermarkArea
                      )
                  : factory.points && factory.points.length > 0
                  ? !pointInArea(
                      {
                        x: factory.points[0].x,
                        y: factory.points[0].y,
                      },
                      watermarkArea
                    )
                  : true;
              }
            }
            return false;
          },
        });
        break;
      default:
        console.error("Invalid type", element.type);
        return undefined;
    }
    return factory;
  };

  extendObservable(plan, {
    elements: Array.isArray(elements)
      ? elements
          .map((data) => {
            return elementFactory(data, elements);
          })
          .filter((e) => e != null)
      : [],
    get backgroundScaledSize() {
      if (plan.background && plan.background.width && plan.background.height) {
        const w = plan.background.width;
        const h = plan.background.height;
        return { w, h };
      }
      return null;
    },
    get width() {
      const defaultWidth = 10000;
      const w =
        plan.backgroundScaledSize && plan.backgroundScaledSize.w > defaultWidth
          ? plan.backgroundScaledSize.w
          : defaultWidth;
      return w;
    },
    get height() {
      const defaultHeight = 10000;
      const h =
        plan.backgroundScaledSize &&
        plan.backgroundScaledSize.h &&
        plan.backgroundScaledSize.h > defaultHeight
          ? plan.backgroundScaledSize.h
          : defaultHeight;
      return h;
    },
    getSystemElementsByType(type) {
      return this.systemElements.filter((e) => e.systemType === type);
    },
    getElementsByType(type) {
      return plan.elements.filter((e) => e?.type === type);
    },
    get areas() {
      return this.getElementsByType("area").sort(IrrigationQuantitySorter);
    },
    get sprinklers() {
      return this.getElementsByType("sprinkler");
    },
    get sensors() {
      return this.getElementsByType("sensor");
    },
    get systemElements() {
      return this.getElementsByType("system-element");
    },
    get irrigationValveCable() {
      return this.getElementsByType("irrigationValveCable");
    },
    get waterMeterCable() {
      return this.getElementsByType("waterMeterCable");
    },
    get pipes() {
      return this.getElementsByType("pipe");
    },
    get pipePoints() {
      return this.getElementsByType("pipeline-point");
    },
    get sprinklersDistance() {
      return sprinklersDistanceArray(plan.areas, [
        ...plan.sprinklers,
        ...plan.rzws,
        ...plan.raisedBeds,
      ]);
    },
    get rzws() {
      return this.getElementsByType("rzws");
    },
    get raisedBeds() {
      return this.getElementsByType("raised-bed");
    },
    bomForceRecalculation: undefined,
    setBomForceRecalculation: action((val) => {
      plan.bomForceRecalculation = val;
    }),
    selectedPointId: undefined,
    pipelineHasDrawing: false,
    setPipelineHasDrawing: action((val = false) => {
      plan.pipelineHasDrawing = val;
    }),
    setShowSensorPlan: (v = true) => {
      plan.showSensorPlan = v;
    },
    setShowWaterSupplyVideo: action((val = true) => {
      plan.showWaterSupplyVideo = val;
    }),
    setPlanningTime: action((val) => {
      plan.planningTime = val;
    }),
    setMaxStep: action((val) => {
      plan.maxStep = val;
    }),
    removeElementsByType: action((type) => {
      const elems = plan.getElementsByType(type);
      elems.forEach(({ id }) => plan.removeElementById(id));
    }),
    changeOpacity: action((value) => {
      plan.opacity = value;
    }),
    changeBomType: action((value) => {
      plan.bomType = value;
      plan.applyBomType(value, plan.bomItems);
    }),
    applyBomType: action((type, bomItems) => {
      // IPAT-486

      if (type == null) return;

      bomItems.forEach((b) => {
        if (b.bomId === "ZS-GR24") {
          switch (type) {
            case "eco":
              b.changeQuality("eco RB");
              break;
            case "premium":
              b.changeQuality("prem RB");
              break;
            default:
              b.changeQuality("exp RB");
              break;
          }
        } else {
          b.changeQuality(type);
        }

        if (
          type === "eco" &&
          [
            "connectionKit",
            "connectionKit_32",
            "fertilizer",
            "fertilizerSmart",
            "airCompressor",
            "waterFilter",
            "waterFilter_32mm",
            "unterflurBox",
            "unterflurBox_32mm",
            "floorWaterMeter",
            "floorWaterMeter_32mm",
            "wallWaterMeter",
            "wallWaterMeter_32mm",
            "water_meter_cable3_5",
            "water_meter_cable3_15",
            "water_meter_cable3_30",
            "water_meter_cable3_75",
            "smart-gateway",
            "smart-sensor",
            "flags",
            "blulockPipe15_10m",
            "elbowFitting",
            "elbowFitting_25mm",
            "elbowFitting_32mm",
            "pipeCuttingTool",
            "floorCombiBox",
            "floorCombiBox_32mm",
            "wallCombiBox",
            "wallCombiBox_32mm",
          ].includes(b.bomId)
        ) {
          b.changeQuantity(0);
        }
      });
    }),
    setScale: action((value) => {
      plan.scale = value;
    }),
    setScaleLength: action((length) => {
      plan.scaleLength = length;
    }),
    setPipelinesAlgo: action((value) => {
      plan.pipelinesAlgo = value;
    }),
    updateElementsDrop: action((drops) => {
      [...plan.sprinklers, ...plan.rzws, ...plan.raisedBeds].forEach(
        (element) => {
          const drop = drops.find((el) => el.parentId === element.id)?.drop;
          element.changeWaterDrop(drop);
        }
      );
    }),
    addElementToStorage: action((data) => {
      const element = elementFactory(data);
      if (element) {
        if (
          data.type === "system-element" &&
          data.systemType !== "water-tap-point" &&
          data.systemType !== "valve-box"
        ) {
          //remove duplicate of system elements
          const e = plan.getSystemElementsByType(data.systemType)?.[0];
          if (e) {
            plan.removeElementById(e.id);
          }
        }

        //
        if (data.virtual == null && element.pointType === "l-point") {
          element.virtual = true;
        }

        plan.elements.push(element);
      }
      return element;
    }),
    clear: action(() => {
      plan.elements.clear();
    }),
  });

  const removeById = action((id) => {
    plan.elements.replace(plan.elements.filter((e) => e.id !== id));
  });

  const removePipePointById = action((pointId) => {
    let result = [];

    const pipesToRemove = plan.pipes.filter(
      (pipe) => pipe.start === pointId || pipe.end === pointId
    );

    if (pipesToRemove && pipesToRemove.length > 0) {
      if (pipesToRemove.length === 2)
        plan.addPipe({
          start:
            pipesToRemove[0].start === pointId
              ? pipesToRemove[0].end
              : pipesToRemove[0].start,
          end:
            pipesToRemove[1].start === pointId
              ? pipesToRemove[1].end
              : pipesToRemove[1].start,
          color: pipesToRemove[0].color
            ? pipesToRemove[0].color
            : pipesToRemove[1].color
            ? pipesToRemove[1].color
            : undefined,
        });
      pipesToRemove.forEach((pipe) => {
        removeById(pipe.id);
        result.push(pipe.id);
      });
    }

    // deleting single points
    const singlePoints = plan.pipePoints.filter(
      (point) =>
        point.pointType !== "sprinkler-point" &&
        point.pointType !== "rzws-point" &&
        point.pointType !== "raised-bed-point" &&
        point.pointType !== "dripline-point" &&
        !point.isSystemElementPoint &&
        point.linesCount === 0
    );
    if (singlePoints && singlePoints.length > 0) {
      singlePoints.forEach((p) => {
        removeById(p.id);
        result.push(p.id);
      });
    }
    result.push(pointId);
    removeById(pointId);
    return result;
  });

  const removePipeById = action((pipeId) => {
    let result = [];
    const pipe = plan.pipes.find((p) => p.id === pipeId);
    if (pipe) {
      const pipePoints = pipe.points ? pipe.points : [];
      removeById(pipeId);
      result.push(pipeId);
      pipePoints.forEach((pipePoint) => {
        if (
          pipePoint &&
          !pipePoint.isConnectionPoint &&
          (!pipePoint.lines || pipePoint.lines.length === 0)
        ) {
          removeById(pipePoint.id);
          result.push(pipePoint.id);
        }
      });
    }
    return result;
  });

  extendObservable(plan, {
    findIndexById: (id) => {
      return plan.elements.findIndex((e) => e.id === id);
    },
    findById: (id) => {
      return plan.elements.find((e) => e.id === id);
    },
    /**
     * Removes elemenents by id
     * also removes associated elements if required.
     *
     * Returns an array of removed elements ids.
     */
    removeElementById: action((...ids) => {
      let result = [];
      ids.forEach((id) => {
        const el = plan.elements.find((e) => e.id === id);
        let removedIds;
        if (el) {
          switch (el.type) {
            case "pipeline-point":
              removedIds = removePipePointById(id);
              plan.pipesModified = true;
              break;
            case "pipe":
              removedIds = removePipeById(id);
              plan.pipesModified = true;
              break;
            case "sprinkler":
            case "rzws":
            case "raised-bed":
              removedIds = [id];
              removeById(id);
              plan.sprinklersModified = true;
              break;
            default:
              removedIds = [id];
              removeById(id);
              break;
          }
        }
        if (removedIds != null) {
          result.push(...removedIds);
        }
      });

      return result;
    }),
  });

  injectPlanPipelines(plan, settingsState);
  injectBomItems(plan, settingsState)(bomItems);
  injectPlanReactions(plan);
  injectPlanSerialization(plan)({ offsetX, offsetY, unit, status });

  return plan;
};

export { planFactory };

export default planFactory;
