import React from "react";
import BomType from "./BomType";
import { observer } from "mobx-react";

import BomList from "./BomList";
import useStores from "../../hooks/useStores";

let BomPage = () => {
  const { uiState } = useStores();
  const { step, calculatingTitle, canFetchPrice, fetchPrices, plan } = uiState;

  const isBomPage = step.name === "recommendations";

  React.useEffect(() => {
    if (isBomPage && canFetchPrice) fetchPrices();
  }, [isBomPage, canFetchPrice, fetchPrices]);

  if (!isBomPage) return null;
  if (canFetchPrice === true) return null;
  if (calculatingTitle != null) return null;

  if (plan.bomForceRecalculation === false) {
    return <BomList />;
  }

  return plan.bomType == null ? <BomType /> : <BomList />;
};
BomPage = observer(BomPage);

export default BomPage;
